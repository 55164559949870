<template>
    <main-container>
        <header-container>
            <OPDPackageHeaderComp />
        </header-container>
        <div style="height: 94vh; max-height: 94vh; padding-bottom: 20px; overflow-y: scroll; " id="scrollableDiv" >
            <OPDSelectRegionAndSearchComp @switchTab="switchTab"></OPDSelectRegionAndSearchComp>
            
            <!-- <div id="searchMe" style="display: block; position: relative;  top: 0; width: 100%; max-width: 425px; z-index: 1000;">
                <OPDSelectRegionAndSearchComp @switchTab="switchTab"></OPDSelectRegionAndSearchComp>
            </div>
            <div id="AllDetails" class="allDetail" style="display: none; ; z-index: 10;">
                <div>All Details</div>
                <img src="https://d3vsnl8idgwrlw.cloudfront.net/DropdownIcon.svg" @click="showSelectRegion"/>
            </div> -->
            <body-container v-if="selectedTab == 'hospital'">
                <div v-if="showHospitalLoader" style="height: 60vh; display: flex; flex: 1;  align-items:center; justify-content: center;">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/hospitalSearchGIF.gif" alt="" style="width: 250px; height: auto;">
                </div>
                <div v-else style="display: flex; flex-direction: column; gap: 16px; padding-top: 16px; position: relative; top: 0px;">
                    <div v-if="getHospitalList.length == 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 4px; color: grey;  height: 60vh;">
                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/noContentFound.svg" alt="" style="width: 80px; height: 80px;">
                        {{$t("Customer.OpdAppointments.No_hospital_found")}}
                    </div>     
                    <div v-for="(item) in getHospitalList" :key="item.hospitalRegNumber">
                        <SingleHospitalComp :hospitalData="item" />
                    </div>
                </div>
            </body-container>
            <div style="width: 100%;" v-else>
                <!-- <div v-if="showHospitalLoader" style="height: 60vh; display: flex; flex: 1;  align-items:center; justify-content: center;">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/packageSearchGif.gif" alt="" style="width: 250px; height: auto;">
                </div> -->
                <div >
                    <PackageFlowComp />
                </div>
            </div>
        </div>
    </main-container>
</template>
<script>
import axios from "axios";
import BodyContainer from '../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../templates/headerContainerOPDBooking.vue';
import mainContainer from '../../../templates/mainContainer.vue';
import HospitalSkeletonLoader from '../../OPDBookingPages/componentUsedInPage/opdHomePage/hospitalSkeletonLoader.vue';
import SingleHospitalComp from '../../OPDBookingPages/componentUsedInPage/opdHomePage/singleHospitalComp.vue';
import OPDPackageHeaderComp from '../componentsUsedInPages/OPDPacakgeHomePage/OPDPackageHeaderComp.vue';
import OPDSelectRegionAndSearchComp from '../componentsUsedInPages/OPDPacakgeHomePage/OPDSelectRegionAndSearchComp.vue';
import PackageFlowComp from '../componentsUsedInPages/OPDPacakgeHomePage/packageFlowComp.vue';
export default {
  components: { mainContainer, BodyContainer, HeaderContainer, OPDPackageHeaderComp, OPDSelectRegionAndSearchComp, SingleHospitalComp, HospitalSkeletonLoader, PackageFlowComp,  },
    name: 'OPDPackageHomePage',
    data: function() {
        return {
            token: null,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            searchBoxDiv: null,
            allDetailsDiv: null,
            selectedTab: 'hospital',
        };
    },
    computed: {
        showHospitalLoader () {
            return this.$store.state.opdPackageState.fetchingData;        
        },
        getHospitalList() {
            return this.$store.state.opdPackageState.showHospitalList || [];        
        }
    },
    async mounted() {
        if (!window.xm)
			this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        // this.scrollableDiv = document.getElementById('scrollableDiv');
        // this.searchMeDiv = document.getElementById('searchMe');
        // this.searchBoxDiv = document.getElementById('searchBox');
        // this.allDetailsDiv = document.getElementById('AllDetails');
        // this.scrollableDiv.addEventListener('scroll', this.scrollHandler);
        this.$store.dispatch('opdPackageState/resetOPDPackageState');
        this.$store.dispatch('opdPackageState/loadHospitalList');
        this.$store.dispatch('loadLocationList');
        this.$store.dispatch('OPDConfigCommonState/loadPackageCategory');        

        this.userPageViewTable();
    },
    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'HospitalPackageHomePage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'HospitalPackageHomePage',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        switchTab(value) {
            this.selectedTab = value;
        },
        // scrollHandler() {
        //     this.searchMeDiv.style.position = 'relative';
        //     this.searchMeDiv.style.top = 0;
        //     let rect = this.searchBoxDiv.getBoundingClientRect();
        //     let divTop = rect.top + window.scrollY;
        //     console.log(divTop)
        //     if(divTop < 10) {
        //     this.searchMeDiv.style.display = 'none';
        //     this.allDetailsDiv.style.display = 'flex'
        //     }

        // },
        showSelectRegion() {
            // this.scrollableDiv.removeEventListener('scroll', this.scrollHandler);
            // this.searchMeDiv.style.display = 'block';
            // this.searchMeDiv.style.position = 'sticky';
            // this.allDetailsDiv.style.display = 'none'
            // setTimeout(() => {
            //     this.scrollableDiv.addEventListener('scroll', this.scrollHandler);
            // }, 1000)
        },
    },
};
</script>
<style  scoped>
.allDetail {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background: white;
    border-bottom: 1px solid #ebebeb;
    position: sticky;
    max-width: 425px;
    top: 0;
}
</style>